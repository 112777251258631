import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { EffectFade, Navigation } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/effect-fade';
import 'swiper/css/navigation';
import Image from 'next/image';
import _ from 'lodash';
import styled from '@emotion/styled';
import cx from 'classnames';

const images = _.range(1, 32).map(i => `/images/mobile-home/Depletion_summary-${i}.png`);

const StyledSwiper = styled(Swiper)`
    .swiper-button-prev,
    .swiper-button-next {
        color: rgba(98, 84, 102, 0.9);
    }
`;

interface MobileHomeProps {
    className?: string;
}

const MobileHome = ({ className }: MobileHomeProps) => (
    <StyledSwiper
        navigation
        modules={[Navigation, EffectFade]}
        effect="fade"
        className={cx('bg-primary h-screen w-full overflow-hidden -mt-24', className)}
    >
        {images.map(src => (
            <SwiperSlide
                key={src}
                style={{
                    backgroundImage: 'url(/images/mobile-home/mobile-home.png)',
                    backgroundSize: 'cover',
                }}
            >
                <Image src={src} alt={src} fill style={{ objectFit: 'contain' }} priority />
            </SwiperSlide>
        ))}
    </StyledSwiper>
);

export default MobileHome;
